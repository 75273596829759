.modal-open {
  overflow: hidden;
}

.modal-overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(17, 18, 20, 0.85);
}

.modal-content {
  max-width: 550px;
  width: 100%;

  padding: 0 8px;

}

.header {
  margin-bottom: 15px;
  font-size: 20px;
  color: #ffffff;
}

.body {
  padding: 20px;
  background-color: #581c87;
  border-radius: 8px;
}

.wallets-grid {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
}

.wallet-button {
  display: flex;
  align-items: center;
  gap: 20px;
  color: #ffffff;

  border-radius: 30px;
  border: 1px solid #2b012c;
  cursor: pointer;
  background-color: #1a011a;

  h3 {
    font-weight: normal;
  }

  img {
    width: 40px;
    height: 40px;
  }
}