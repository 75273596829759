.network {
  position: relative;
}

.button {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.menu {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  width: calc(100% + 50px);
  margin-top: 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  color: #262626;
}

.option {
  display: flex;
  align-items: center;
  gap: 15px;

  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  .status {
    width: 10px;
    height: 10px;
    margin-left: auto;
    border-radius: 50%;
    background-color: green;
  }
}